(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/casino-breadcrumbs/views/casino-breadcrumbs.js') >= 0) return;  svs.modules.push('/widgets/casino-breadcrumbs/views/casino-breadcrumbs.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widget=_svs.widget||{};
_svs.widget.casino_breadcrumbs=_svs.widget.casino_breadcrumbs||{};
_svs.widget.casino_breadcrumbs.templates=_svs.widget.casino_breadcrumbs.templates||{};
svs.widget.casino_breadcrumbs.templates.casino_breadcrumbs = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget-default widget-full-width widget-full-height js-slim-breadcrumbs"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? lookupProperty(stack1,"marginOver") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":83},"end":{"line":2,"column":141}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? lookupProperty(stack1,"marginUnder") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":141},"end":{"line":2,"column":203}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifAll")||(depth0 && lookupProperty(depth0,"ifAll"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"ifAll","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":14}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " margin-top-2";
},"4":function(container,depth0,helpers,partials,data) {
    return "";
},"6":function(container,depth0,helpers,partials,data) {
    return " margin-bottom-2";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-breadcrumbs-breadcrumbs_slim"),depth0,{"name":"components-lb_ui-breadcrumbs-breadcrumbs_slim","hash":{"breadcrumbs":(depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currentWidget") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":9}}})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true});
Handlebars.partials['widget-casino_breadcrumbs-casino_breadcrumbs'] = svs.widget.casino_breadcrumbs.templates.casino_breadcrumbs;
})(svs, Handlebars);


 })(window);